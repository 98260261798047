<template lang="pug">
b-modal#wancionexchange-content(
  v-model="showModal"
  title="獎勵說明", 
  title-class="font-18", 
  body-class="p-4" 
  size="md"
)
  p {{ content }}
  template(#modal-footer='{ ok }')
    b-button(size='md' variant='primary' @click='ok()')
      | 我知道了

</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showModal: this.show
    }
  },
  watch: {
    show(_val) {
      this.showModal = this.show
    }
  }
}

</script>
<style lang="sass" scoped>
.sort-item
  display: flex
  align-items: center
  gap: 20px
  background: #f1f5f9
  margin-bottom: 5px
  cursor: pointer

.ad-item
  background: #FF846E4D

.forbid
  cursor: not-allowed

.draggable
  cursor: grab
</style>
