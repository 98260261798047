import { render, staticRenderFns } from "./WancionExchange.vue?vue&type=template&id=15f032dd&scoped=true&lang=pug"
import script from "./WancionExchange.vue?vue&type=script&lang=js"
export * from "./WancionExchange.vue?vue&type=script&lang=js"
import style0 from "./WancionExchange.vue?vue&type=style&index=0&id=15f032dd&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15f032dd",
  null
  
)

export default component.exports