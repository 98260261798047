<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { tableData } from "./../data";
import WancoinContent from '@/components/WancoinContent'

/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data() {
    return {
      title: "旺幣兌換設定/查詢",
      items: [
        {
          text: "用戶等級任務管理",
          href: "/usermanagement",
        },
        {
          text: "旺幣兌換設定/查詢",
          active: true,
        }
      ],
      searchData:{
        name: "",
        status: null
      },
      value:'',
      daterange:'',
      visible: true,
      statusOptions: [
        { id: 0, name: '未兌換' },
        { id: 1, name: '已兌換完畢' },
        { id: 2, name: '兌換中' }
      ],

      tableData: tableData,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "name",
          label: "兌換品名",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "wancoin",
          label: "兌換旺幣",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "payed_count",
          label: "已兌換",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "start_date",
          label: "兌換期間",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "content",
          label: "獎勵說明",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "updated_at",
          label: "更新時間",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "action",
          label: "操作",
          sortable: false,
          stickyColumn: true,
          class: 'width-auto center'
        }
      ],
      currentContent: "",
      showContentModal: false,
      tab: 1
    };
  },
  computed:{
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.reloadList()
  },
  methods: {
    reloadList () {
      this.$refs.searchTable.refresh()
    },
    searchList (_table, _callback) {
      const vm = this

      const searchParam = {
        page: this.currentPage || 1,
        name: this.searchData.name || null,
        status: this.searchData.status?.id ?? null
      }
      this.$root.apis.getWancoinCouponList(searchParam, 
        function (_response) {
          let response = _response.body.data
          vm.totalRows = response.total
          vm.currentPage = response.page
          vm.perPage = response.per_page
          if (typeof _callback === 'function') {
            vm.tableData = response.items
            _callback(vm.tableData)
          }
        },
        function (_error) {
          console.error(_error)
          if (typeof _callback === 'function') {
            _callback([])
          }
        })
    },
    clickShow(_content) {
      this.currentContent = _content
      this.showContentModal = true
    },
    setExchangeItem(_item) {
      const { status, name, id } = _item
      const action = status ? "下架" : "上架"
      const vm = this

      this.$root.common.confirmAction(`${action}兌換項目`, `是否將${name}${action}`, action, "再想一下",
        function (_result) {
          status 
          ? vm.$root.apis.setdownWancoinExchangeItem(id, 
            function (_response) {
              vm.$root.common.showSingleNotify('下架成功！')
              vm.reloadList()
            }, 
            vm.onAPIError)
          : vm.$root.apis.setupWancoinExchangeItem(id, 
            function (_response) {
              vm.$root.common.showSingleNotify('上架成功！')
              vm.reloadList()
            }, 
            vm.onAPIError) 
        }
      )
    },
    onAPIError(_error) {
      console.error(_error)
      this.$root.common.showErrorNotify(_error.body.data)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    WancoinContent
  }
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  WancoinContent(:content="currentContent" :show="showContentModal")
  .row
    .col-lg-12
      //- 搜尋bar
      b-card.col-lg-12.mb-1(no-body)
        b-collapse#customaccordion-1(v-model="visible", accordion="accordion", role="tabpanel")
          b-card-body
            form.form-horizontal(role="form")
            .row
              .col-md-4.my-1
                label 兌換品名
                input.form-control(
                  v-model="searchData.name"
                  type="text", 
                  placeholder="請輸入品名"
                )
              .col-md-4.my-1
                label 兌換狀態
                multiselect(
                  v-model="searchData.status", 
                  :options="statusOptions", 
                  placeholder="請選擇區域"
                  trackBy="id"
                  label="name"
                  select-label=""
                )
              .col-md-4.my-1.d-flex.justify-content-end.align-items-end
                b-button.width-lg(variant="primary" @click="reloadList") 查詢
      .row.mt-3.mb-3.mr-0
        .col-lg-12.row.d-flex.justify-content-end
          b-button.width-lg(variant="primary" to="/wancionexchange/setting") 新增兌換項目      
      //- 表格
      .card
        .card-body
          .header-row.mb-3
          //- Table
          .row
            .col-12
              b-table(
                ref="searchTable"
                :items="searchList",
                :fields="fields",
                responsive,
                :per-page="perPage",
                :current-page="currentPage",
                :sort-desc.sync="sortDesc",
                :filter="filter",
                :filter-included-fields="filterOn",
                @filtered="onFiltered")

                template(v-slot:cell(payed_count)="data")
                  b-button.table-btn(:to="`/wancionexchangeList/${data.item.id}?name=${data.item.name}&price=${data.item.price}`") {{ data.item.payed_count }}

                template(v-slot:cell(start_date)="data")
                  span {{ data.item.start_date }} {{ data.item.start_time }}
                  br
                  span {{ data.item.end_date }} {{ data.item.end_time }}
                
                template(v-slot:cell(content)="data")
                  b-button.table-btn(
                    variant="primary" 
                    v-b-modal.wancionexchange-content 
                    @click="clickShow(data.item.content)") 查看

                template(v-slot:cell(updated_at)="data")
                  span {{ data.item.updated_at }}
                  br
                  span {{ data.item?.editer?.name || '' }}

                //- action
                template(v-slot:cell(action)="data")
                  .d-flex.align-items-center.justify-content-center
                    b-button.table-btn.mr-2(:to="`/wancionexchange/setting/${data.item.id}`") 編輯 
                    span.table-btn.mr-2 |  
                    b-button.table-btn.mr-2(v-if="!data.item.status" @click="setExchangeItem(data.item)") 上架 
                    b-button.table-btn.mr-2(v-else @click="setExchangeItem(data.item)") 下架 
                    span.table-btn.mr-2 |  
                    b-button.table-btn() 序號下載

          .row.mb-md-2
            .col-12.row.d-flex.justify-content-end  
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>
<style lang="sass" scoped>
.header-row
  display: flex
  justify-content: space-between
  align-items: center

::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
  &.delete
    color: #333333

::v-deep  .b-table-sticky-column:last-child
  right: 0
  position: sticky !important

::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
</style>
